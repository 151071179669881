import Paragraph from "../atoms/Paragraph";
import Title from "../atoms/Title";
type props = {
  title: string;
  description: string;
  image: string;
};

const HeroSection = ({ image, title, description }: props) => {
  return (
    <div
      style={{
        background: `url(${image})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "450px",
      }}
      id="home"
    >
      <div
        style={{
          background:
            "linear-gradient(89.58deg, #BFBFBE 0.25%, rgba(217, 217, 217, 0) 83.1%)",
        }}
        className="h-full w-full flex items-center"
      >
        <div className="container">
          <div className="max-w-2xl space-y-5">
            <Title className="text-center">{title}</Title>
            <Paragraph className="leading-7">{description}</Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
