import Paragraph from "../atoms/Paragraph";

const FeatureCard = ({
  title,
  image,
  description,
}: {
  title: string;
  image: string;
  description: string;
}) => (
  <div className="w-full md:w-6/12 lg:w-4/12 xl:w-3/12">
    <div className="py-5 space-y-3">
      <Paragraph className="text-center capitalize">{title}</Paragraph>
      <div className="px-5">
        <div
          className="bg-[#FCFDC9] px-5 border-[3px] border-white rounded-2xl space-y-8 py-16 md:min-h-[430px]"
          style={{
            boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
          }}
        >
          <img src={image} className="mx-auto w-[110px]" alt="feature-1" />

          <p className="leading-8 text-sm">{description}</p>
        </div>
      </div>
    </div>
  </div>
);

export default FeatureCard;
