import AboutShufola from "./components/molecules/AboutShufola";
import Awards from "./components/molecules/Awards";
import FarmerSatisfaction from "./components/molecules/FarmerSatisfaction";
import Features from "./components/molecules/Features";
import HeroSection from "./components/molecules/HeroSection";
import HowShufolaWorks from "./components/molecules/HowShufolaWorks";
import Objective from "./components/molecules/Objective";
import Testimonial from "./components/molecules/Testimonial";
import MOCKS from "./data/mocks";
import Layouts from "./layouts/Layouts";

const {
  hero,
  about,
  objective,
  howShufolaWorks,
  farmerSatisfaction,
  testimonial,
  features,
  awards,
} = MOCKS;

const App = () => (
  <Layouts>
    <HeroSection {...hero} />
    <AboutShufola {...about} />
    <Objective {...objective} />
    <HowShufolaWorks {...howShufolaWorks} />
    <FarmerSatisfaction {...farmerSatisfaction} />
    <Testimonial {...testimonial} />
    <Features {...features} />
    <Awards {...awards} />
  </Layouts>
);

export default App;
