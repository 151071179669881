import React, { ReactNode } from "react";
import classNames from "../../utils/classes";

type props = {
  children: ReactNode;
  className?: string;
};
const Title: React.FC<props> = ({ children, className = "" }) => (
  <h3 className={classNames("text-3xl lg:text-4xl font-normal", className)}>
    {children}
  </h3>
);

export default Title;
