import Paragraph from "../atoms/Paragraph";
import Title from "../atoms/Title";

const AboutShufola = ({
  title,
  description,
  image,
}: {
  title: string;
  image: string;
  description: string;
}) => (
  <div
    style={{
      background:
        "linear-gradient(180deg, rgba(254, 255, 242, 0)  0%,#FEFFCE 100%)",
    }}
    className="py-20"
    id="about"
  >
    <div className="container space-y-20">
      <Title className="text-center">{title}</Title>
      <div className="grid lg:grid-cols-2 gap-20 lg:gap-8">
        <div>
          <img src={image} alt="about" className="w-full" />
        </div>
        <div className="flex items-center">
          <Paragraph className="text-center leading-9">{description}</Paragraph>
        </div>
      </div>
    </div>
  </div>
);

export default AboutShufola;
