import Paragraph from "../atoms/Paragraph";
import Title from "../atoms/Title";

const Objective = ({ title, items }: { title: string; items: any[] }) => (
  <div className="py-20">
    <div className="container space-y-20">
      <Title className="text-center">{title}</Title>
      <div className="grid md:grid-cols-3 gap-9">
        {items.map((item) => (
          <div className="space-y-8">
            <div className="max-w-[207px] mx-auto">
              <img
                src={item.image}
                alt="objective"
                className="w-full drop-shadow-md"
              />
            </div>
            <Paragraph className="text-center leading-9">
              {item.description}
            </Paragraph>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Objective;
