import Paragraph from "../atoms/Paragraph";
import Title from "../atoms/Title";

const Testimonial = ({
  title,
  description,
  video,
}: {
  title: string;
  description: string;
  video: string;
}) => (
  <div
    style={{
      background:
        "linear-gradient(30.27deg, #F5F4EE 19.54%, rgba(255, 249, 228, 0) 100%)",
    }}
    className="py-20"
  >
    <div className="container space-y-16">
      <Title className="text-center">{title}</Title>
      <div className="space-y-12">
        <Paragraph className="text-center">{description}</Paragraph>

        <iframe
          width={560}
          height={315}
          src={video}
          title="SHUFOLA - A Localized Weather Based Agro Advisory"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="w-full md:max-w-xl mx-auto"
        />
      </div>
    </div>
  </div>
);

export default Testimonial;
