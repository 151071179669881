import Paragraph from "../atoms/Paragraph";

const AwardCard = ({
  color,
  image,
  title,
  subTitle,
  count,
  counterTitle,
}: {
  color: string;
  image: string;
  title: string;
  subTitle: string;
  count: string;
  counterTitle: string;
}) => (
  <div className="py-16 text-center space-y-8" style={{ background: color }}>
    <div className="min-h-[100px] flex items-center">
      <img src={image} alt="awards" className="mx-auto" />
    </div>
    <div>
      <h5 className="text-3xl lg:text-4xl font-medium">{title}</h5>
      <Paragraph className="text-shufolaOliveGreen">{subTitle}</Paragraph>
    </div>
    <div>
      <h5 className="text-3xl lg:text-4xl font-semibold text-shufolaOrange">
        {count}
      </h5>
      <Paragraph className="text-shufolaOrange">{counterTitle}</Paragraph>
    </div>
  </div>
);

export default AwardCard;
