import Title from "../atoms/Title";

const HowShufolaWorks = ({
  title,
  image,
}: {
  title: string;
  image: string;
}) => (
  <div className="py-20 bg-[#FEFFF2]" id="how-shufola-works">
    <div className="container space-y-20">
      <Title className="text-center">{title}</Title>
      <img
        src={image}
        alt="show-shufola-works"
        className="max-w-4xl mx-auto w-full"
      />
    </div>
  </div>
);

export default HowShufolaWorks;
