import { useState } from "react";
import TopHeader from "../components/atoms/TopHeader";
import Navigation from "../components/molecules/Navigation";

const Header = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TopHeader setOpen={setOpen} />
      <Navigation open={open} setOpen={setOpen} />
    </>
  );
};

export default Header;
