import Title from "../atoms/Title";
import AwardCard from "./AwardCard";

const Awards = ({ title, items }: { title: string; items: any[] }) => (
  <div className="pt-20">
    <div className="bg-shufolaOrange py-5">
      <Title className="text-center font-medium">{title}</Title>
    </div>
    <div className="grid lg:grid-cols-3">
      {items.map((item) => (
        <AwardCard {...item} />
      ))}
    </div>
  </div>
);

export default Awards;
