import Title from "../atoms/Title";
import FeatureCard from "./FeatureCard";

type item = {
  title: string;
  description: string;
  image: string;
};

const Features = ({ title, items }: { title: string; items: item[] }) => {
  return (
    <div
      style={{
        background:
          "linear-gradient(180deg, rgba(254, 255, 242, 0) 70%, #FEFFCE 100%)",
      }}
      className="py-20"
      id="features"
    >
      <div className="container space-y-20">
        <Title className="text-center">{title}</Title>
        <div className="flex flex-wrap justify-center">
          {items?.map((item) => (
            <FeatureCard
              image={item.image}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
