const PhoneNumber = ({ phone }: { phone: string }) => (
  <div className="flex items-center space-x-1">
    <svg
      width={18}
      height={22}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_62_1411)">
        <path
          d="M12.3035 14.6545C12.3601 14.6196 12.4139 14.5803 12.4641 14.5371C12.5028 14.5102 12.5387 14.4796 12.5712 14.4457L12.6984 14.3544L12.7988 14.2696C12.9459 13.9798 13.0088 13.6563 12.9804 13.3342C12.9521 13.0121 12.8337 12.7038 12.6382 12.4427C11.9687 11.3596 10.6768 10.3809 10.0141 10.3809C9.92374 10.3789 9.83496 10.404 9.75969 10.4527L9.53878 10.6288L9.40489 10.7332L9.25093 10.8572C9.12487 10.9607 8.96709 11.0203 8.80243 11.0268C8.74855 11.029 8.69484 11.0196 8.6451 10.9993C8.59536 10.979 8.55081 10.9483 8.51458 10.9094L8.13301 10.4462C7.62188 9.51626 7.01157 8.64148 6.31221 7.83633C5.6402 6.9974 4.8771 6.23175 4.0362 5.55273L3.66133 5.10905C3.59916 4.99034 3.5853 4.8531 3.62254 4.72485C3.65978 4.5966 3.74537 4.4868 3.86216 4.41745L3.90902 4.3783L4.02282 4.28696L4.22364 4.12385L4.41777 3.97378C4.79934 3.14516 3.62117 1.15516 2.51664 0.352641C2.28403 0.161489 1.99639 0.045249 1.69326 0.0198915C1.63995 0.0129007 1.58591 0.0129007 1.5326 0.0198915L1.33847 0.176475L1.26484 0.235194L1.03054 0.417881H0.997069L0.869881 0.515756L0.789552 0.580996L0.615504 0.724537C-0.904064 2.26434 0.287492 6.3683 3.39357 10.0938C5.91056 13.1017 8.92961 14.9742 10.9713 14.9742C11.4184 14.9846 11.8593 14.8715 12.2432 14.648L12.3035 14.6545Z"
          fill="#3F3F3F"
        />
      </g>
      <defs>
        <clipPath id="clip0_62_1411">
          <rect width={13} height={15} fill="white" />
        </clipPath>
      </defs>
    </svg>

    <a href={`tel:${phone}`}>{phone}</a>
  </div>
);

export default PhoneNumber;
