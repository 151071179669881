import Title from "../atoms/Title";

const FarmerSatisfaction = ({
  title,
  image,
  list,
  nb,
}: {
  title: string;
  image: string;
  list: string[];
  nb: string;
}) => (
  <div className="py-20">
    <div className="space-y-16">
      <Title className="text-center">{title}</Title>
      <div
        style={{
          background: `url(${image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="h-[400px] lg:h-[550px]"
      >
        <div className="container h-full">
          <div className="flex items-center h-full">
            <div className="w-full xl:w-[28%] bg-white/90 p-6 pt-10 rounded-lg space-y-6">
              <ul className="list-disc pl-6 space-y-4">
                {list.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <p className="text-xs">{nb}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FarmerSatisfaction;
