const MOCKS = {
  hero: {
    image: "/assets/img/shufola-hero.png",
    title: "Shufola",
    description:
      "SHUFOLA combines localized weather forecasts with crop management practices to provide actionable advice to smallholder farmers.",
  },
  about: {
    title: "About SHUFOLA",
    image: "/assets/img/shofola-about.png",
    description:
      "SHUFOLA is a mobile App which provides wide range of agri-related services to increase the productivity of farmers. SHUFOLA works as a virtual agricultural consultant 24/7. Among other services, SHUFOLA combines localized weather forecasts with specific crop management practices to generate highly customized actionable advice for individual smallholder farmers.",
  },
  objective: {
    title: "Objective",
    items: [
      {
        image: "/assets/img/objective-1.png",
        description:
          "SHUFOLA aims at creating a digital knowledge hub that famers can easily access and benefit from for their agricultural purposes.",
      },
      {
        image: "/assets/img/objective-2.png",
        description:
          "SHUFOLA provides dynamic and farmer-specific customized advisory so that farmers can take right measures at the right time.",
      },
      {
        image: "/assets/img/objective-3.png",
        description:
          "SHUFOLA makes farmers better prepared for climate change by giving weather-sensitive advisory service.",
      },
    ],
  },
  howShufolaWorks: {
    title: "How SHUFOLA works",
    image: "/assets/img/show-shufola-works.png",
  },
  farmerSatisfaction: {
    title: "Farmer Satisfaction",
    image: "/assets/img/farmer.png",
    list: [
      "95% shared SHUFOLA recommendations with other farmers",
      "Average service rating of 4.3 out of 5",
      "97% willing to register for SHUFOLA next season",
      "40% reported that recommendations included new information",
    ],
    nb: "*Based on four Randomized Control Trials (RCT) conducted by Precision Development (PxD)",
  },
  testimonial: {
    title: "Testimonial",
    description: "SHUFOLA - A Localized Weather Based Agro Advisory",
    video: "https://www.youtube.com/embed/LrO7vVDL5QU",
  },
  features: {
    title: "Features",
    items: [
      {
        image: "/assets/img/icon/feature-icon-1.svg",
        title: "news",
        description:
          "Agricultural news, new innovations, best practices etc. on daily basis",
      },
      {
        image: "/assets/img/icon/feature-icon-2.svg",
        title: "Selection of Right Seeds",
        description:
          "Selection of right variety of seed based on the farmer’s area, soil type, season, etc. in alignment with Soil Resource Development Institute (SRDI)",
      },
      {
        image: "/assets/img/icon/feature-icon-3.svg",
        title: "Early Detection of Diseases",
        description: "Easy diagnosis of disease and insect by matching images",
      },
      {
        image: "/assets/img/icon/feature-icon-4.svg",
        title: "Agricultural Knowledge Bank",
        description:
          "Repository of agricultural knowledge about seeds, sowing/planting, varieties, soil, fertilizers, marketing, etc. curated from government contents",
      },
      {
        image: "/assets/img/icon/feature-icon-5.svg",
        title: "Expert Advice",
        description: "Tele-consultation with agriculture expert",
      },
      {
        image: "/assets/img/icon/feature-icon-6.svg",
        title: "Agricultural Inputs Database",
        description:
          "Easily accessible database of inputs including seeds, fertilizers, pesticides, herbicides, etc",
      },
      {
        image: "/assets/img/icon/feature-icon-7.svg",
        title: "My Cultivation",
        description:
          "Customized advisories for seed-to-seed management practices and adverse climatic events.",
      },
    ],
  },
  awards: {
    title: "Awards",
    items: [
      {
        color: "#FFF1F1",
        image: "/assets/img/award-1.png",
        title: "Best Innovation",
        subTitle: "Agriculture Sector",
        count: "500+",
        counterTitle: "Applicants from BD",
      },

      {
        color: "#FFF7E9",
        image: "/assets/img/award-2.png",
        title: "Winner",
        subTitle: "Environment & Green Energy Category",
        count: "400+ Nominees",
        counterTitle: "110 Countries",
      },
      {
        color: "#FFF1F1",
        image: "/assets/img/award-3.png",
        title: "Finalist",
        subTitle: "e-Agriculture Category",
        count: "1100+ Applicants",
        counterTitle: "180 Countries",
      },
    ],
  },
};

export default MOCKS;
