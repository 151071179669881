export const navigation = {
  menu: [
    {
      title: "Home",
      path: "#home",
    },
    {
      title: "About",
      path: "#about",
    },
    {
      title: "How Shufola works",
      path: "#how-shufola-works",
    },
    {
      title: "Features",
      path: "#features",
    },
  ],
  phone: "01987-6543-21",
};
